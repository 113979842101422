import React, { useState } from 'react';

import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Button,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';

import './HomeLayout.css';
import '../../styles/display-container.css';
import '../../styles/card.css';
import '../../styles/spacing.css';

const items = [
  {
    src: '/images/pared-sur-people-one.jpg',
    caption: 'The Founders of Pared Sur'
  },
  {
    src: '/images/vineyard-with-mountain-range.jpg',
    caption: 'A stunning view of Pared Sur'
  },
  {
    src: '/images/pared-sur-vintage-barrels.jpg',
    caption: 'Our upcoming vintage'
  },
  {
    src: '/images/vines-and-scenery.jpg',
    caption: 'Almost harvest season near Pared Sur'
  }
];

export const HomeLayout = (props) => {
  const [activeLanguage, setLanguage] = useState('en');
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const setEnglish = () => {
    setLanguage('en');
  }

  const setSpanish = () => {
    setLanguage('es');
  }

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.caption} width='100%' />
        <CarouselCaption captionHeader={item.caption} captionText='' />
      </CarouselItem>
    );
  });

  return (
    <div className='home-page screen-container'>
      <div className='home-hero-image-container home-hero-image'>
        <div className='home-hero-image-banner-parent'>
          <div className='home-hero-image-banner'>
            <div className='home-hero-image-banner-text'>Pared Sur Wines</div>
          </div>
        </div>
      </div>
      <div className='d-flex flex-column flex-nowrap justify-content-between display-container'>
        <Card className='large-card'>
          <CardBody>
            {
              'en' === activeLanguage
                ? <CardTitle>Our History</CardTitle>
                : <CardTitle>Nuestra Historia</CardTitle>
            }
            {
              'en' === activeLanguage
                ? <CardText>
                  Polina S.A. is an entrepreneurship of three partners who live in Mendoza and are
                  lovers of the agricultural tradition of this region. Agronomist Alejandro Ceresa
                  is an expert in traceability and quality standards, while Agronomist Julio Vega
                  is an expert in the advice and management of high-end vineyards. Licensed
                  Oenologist Liliana Iannizzotto brings with her 26 years of experience producing
                  high quality wines in well-known wineries. The vast experience and extensive
                  knowledge of its owners has made it possible to select the best wines from the
                  terroirs most suitable for the varietals of Cabernet Sauvignon and Malbec.
                </CardText>
                : <CardText>
                  Polina S.A.  es un  emprendimiento  de mendocinos íntimamente  involucrados  con la industria
                  vitivinícola y  amantes de la tradición agrícola de nuestra región. La vasta trayectoria y dilatada
                  experiencia de sus propietarios en el sector  vitivinícola los Ing. Agrónomos Alejandro Ceresa,
                  experto en trazabilidad y normas de calidad y  Julio Vega  experto en el asesoramiento y manejo
                  de viñedos de alta gama y contando con el conocimiento de la Winemaker Licenciada  Liliana
                  Iannizzotto  con 26 años de experiencia elaborando vinos de alta calidad en reconocidas bodegas
                  del medio. Esta conjunción de experiencias ha permitido  seleccionar las mejores partidas de vinos
                  de los  terruños más aptos para las varietales Cabernet Sauvignon y Malbec.
                </CardText>
            }
            {
              'en' === activeLanguage
                ? <CardText>
                  The company began its winemaking activity in 1998 with the planning of a
                  vineyard in Perdriel, Lujan de Cuyo in the Province of Mendoza, where the focus
                  was on strains of the Cabernet Sauvignon variety. For our first wine, we chose
                  the name Pared Sur as a tribute to the most difficult climbing of Aconcagua, the
                  highest mountain in the American continent. For the second wine, the chosen name
                  is a tribute to the hard-working tutor of our vineyards, Mr. Martin Carrera, who
                  was tasked with taking care of the vines from birth to adulthood.
                </CardText>
                : <CardText>
                  La  empresa comenzó su actividad en 1998 con la plantación  de un viñedo en el
                  privilegiado terroir de Perdriel, Lujan de Cuyo en la Provincia de Mendoza,  con
                  cepas de la variedad Cabernet Sauvignon. Para nuestro primer vino elegimos el nombre
                  Pared Sur, como homenaje a la escalada más dificultosa del  Aconcagua,   montaña más
                  alta del continente Americano.  Para el segundo vino  el nombre elegido presenta un
                  sentido  homenaje al esforzado trabajador y tutor de nuestros viñedos, Don Martin
                  Carrera, quien fuese el encargado de cuidar las vides desde su nacimiento hasta la
                  adultez del viñedo.
                </CardText>
            }
            {
              'en' === activeLanguage
                ? <CardText>
                  In a second stage, we decided to look for new expressions of the varietals on the
                  market, extending our search to new viticultural regions. With this objective, we
                  sought out wineries with grapes that, with the oenological care of our winemaker,
                  could be the future wines of Martin Carrera Reserva and Pared Sur. Our expertise
                  allows us to choose the best grapes every year from the selected wineries,
                  allowing us to obtain only the best wines for our exports.
                </CardText>
                : <CardText>
                  En una segunda etapa decidimos buscar nuevas expresiones de los varietales
                  comercializados extendiendo  nuestra búsqueda a nuevas regiones vitícolas. Con este
                  objetivo recorrimos bodegas elaboradoras de mostos bases que con  los cuidados
                  enológicos de nuestra winemaker   pudieran ser los futuros  vinos Martin Carrera
                  Reserva y Pared Sur.  Nuestro expertise nos permite elegir las mejores partidas
                  de mostos  cada año y elaborarlos en las bodegas seleccionadas,  obteniendo los
                  vinos para nuestras exportaciones.
                </CardText>
            }
            <Button color='link' active={'en' === activeLanguage} onClick={setEnglish}>English</Button>{' '}
            <Button color='link' active={'es' === activeLanguage} onClick={setSpanish}>Español</Button>{' '}
            <Button color='link' active={false} target="_blank" href="/downloads/pared-sur-wines.pdf">Chinese</Button>
          </CardBody>
        </Card>
        <Card className='section-top-spacing'>
          <CardBody>
            <CardTitle>Our Vineyard</CardTitle>
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
            >
              <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
              {slides}
              <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
              <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
            </Carousel>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
