import React from 'react';

import 'bootswatch/dist/yeti/bootstrap.css';
import './App.css';

import {ApplicationHeader} from "./components/header/ApplicaitonHeader";
import {HomeLayout} from "./layouts/home/HomeLayout";

function App() {
  return (
    <div className="App d-flex flex-column flex-nowrap justify-content-between application-view-port">
      <ApplicationHeader />
      <HomeLayout />
    </div>
  );
}

export default App;
